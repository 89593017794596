@mixin wrapper($color) {
  user-select: none;
  border: 1px solid $color;
  border-radius: 8px;
  padding: 2.4rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
}
.selectCardWrapper {
  @include wrapper(#dadada);
}
.selectCardWrapperActives {
  @include wrapper(#156bec);
}
.cardFirsRow {
  font-size: 1.8rem;
  color: #333;
}
.circleStyle {
  color: #156bec;
}
.subtitle {
  font-size: 1.2rem;
  margin-top: 1.2rem;
  color: #777;
}

.discount {
  line-height: 14.09px;
  font-size: 1.2rem;
  color: #777;
  text-decoration: line-through;
}
p {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
}
