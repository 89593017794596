.congratulationWrapper {
  .inTouchIMGWrapper {
    display: none;
  }
  .titlecontent {
    display: flex;

    line-height: 28.18px;
    margin-top: 12%;
    // padding-bottom: 4rem;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .subtitle {
    font-size: 4.8rem;
    font-weight: 600;
    line-height: 56.35px;
    margin-bottom: 3.2rem;
    display: inline-block;
  }
  .info,
  .download {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 24px;
    display: block;
  }
  .scheduleTitle {
    display: inline-block;
    color: #000;
    padding: 3.2rem 0 1.6rem 0;
    margin: 3.2rem 0 0 0;
    font-weight: bold;
    font-size: 2.4rem;
    border-top: 1px solid #dfdfdf;
    width: 100%;
  }
  .serviceCenter {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 18.78px;
    display: inline-block;

    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
  }
  .deliveryInfoBox {
    margin-bottom: 3.8rem;
    div div {
      margin-bottom: 1rem;
      font-size: 1.4rem;
      line-height: 18px;
    }
  }
  .iconContainer {
    .icons {
      width: 15.5rem;
      height: 6rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .congratulationWrapper {
    .inTouchIMGWrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
      .inTouchIMG {
        display: block;
        width: 25.1rem;
        height: 20.1rem;
      }
    }
    .titlecontent {
      display: grid;
      grid-template-columns: 100%;
      margin-top: 0%;
      padding-bottom: 0rem;
    }

    .iconContainer {
      display: flex;
      .icons {
        display: block;
      }
    }
    .scheduleTitle {
      padding: 3.2rem 0 3.2rem 0;
      font-size: 2rem;
    }
    .subtitle {
      font-size: 2.4rem;
      line-height: 28.18px;
      margin-bottom: 2.4rem;
    }
  }
}
