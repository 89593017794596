.titleWrapper {
  text-align: center;
  padding-bottom: 4rem;
  margin-left: -11%;
  .title {
    font-size: 2.4rem;
    // font-family: Raleway-SemiBold;
    font-family: Maax;
    font-weight: 500;
    color: #000;
  }
}

@media screen and (max-width: 768px) {
  .titleWrapper {
    text-align: center;
    background-color: #156bec;
    padding-top: 5.5rem;
    // margin-left: -10%;
    .title {
      color: #fff;
    }
  }
}
