.packageChooseWrapper {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .subtitle {
  }
}

@media screen and (max-width: 768px) {
  .packageChooseWrapper {
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
  }
}
