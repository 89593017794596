.inValidZipCodeWrapper {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .inTouchIMGWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    .inTouchIMG {
      display: block;
      width: 25.1rem;
      height: 20.1rem;
    }
  }
  .subtitle {
    display: inline-block;
    text-align: center;
    margin-bottom: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .inValidZipCodeWrapper {
    .titlecontent {
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
    .subtitle {
      display: inline-block;
      text-align: center;
      margin-bottom: 0rem;
    }
  }
}
