@mixin inputGrid {
  display: grid;
  align-items: baseline;
  justify-content: space-between;
  margin-top: -15px;
}

.paymentWrapper {
  margin-bottom: 3.2rem;
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .paymentDetailWrapper {
    margin-bottom: 3.2rem;
  }
  .sectionSubtitle {
    font-size: 1.6rem;
    color: #333;
  }
  .inputWrapper {
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
    width: 100%;
    .input {
      border-radius: 5px;
      height: 4.8rem;
    }
  }

  .inputWrapperSelect {
    margin-top: 1rem;
    margin-bottom: 3.2rem;
    width: 100%;
    .input {
      border-radius: 5px;
      height: 4.8rem;
    }
  }

  .addressInfo {
    display: grid;
    margin-top: -15px;
    justify-content: space-between;
    grid-template-columns: 30% 30% 30%;
    height: 9.4rem;
  }
  .paymentWrapper {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #dadada;
    border: 1px solid #dadada;
    padding: 5px;
    border-radius: 8px;

    .paymentWrapperText {
      display: inline-block;
    }
  }
  .paymentInfo {
    display: grid;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    grid-template-columns: 49% 49%;
  }
  .promoCodeWrapper {
    display: flex;
    align-items: baseline;
    column-gap: 20px;
    margin-top: 0rem;
    height: 8rem;
    .promoCodeApplyButton {
      width: 30%;
  
      height: 4.6rem;
      border-radius: 0.4rem;
      margin-top: 0;
    }
  }
}

.checkbox {
  font-size: 1.6rem;
}

.strapInput {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: black;
  padding: 1.5rem 1rem;
}
.buttonWrapperText {
  margin-top: 0.8rem !important;
}
.orderFailed {
  position: absolute;
  color: red;

  margin-left: calc(50% - 6.3rem);
}
@media screen and (max-width: 768px) {
  .paymentWrapper {
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
    .sectionSubtitle {
      display: inline-block;
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
    }
    .addressInfo {
      grid-template-columns: 100%;
      margin-top: 0px;
      height: 29rem;
    }

    .inputWrapper {
      margin-top: 0rem;
    }
  }
  .promoCodeWrapper {
    height: 5.2rem;
  }
}
