.prevButton {
  position: relative;
  width: 0px;
  background: none !important;
  border: none;
  box-shadow: none;
  padding: 5px 0;
  z-index: 1;
}
.icon {
  color: #777777;
  font-size: 30px;
  font-weight: bolder;
  left: 0px;
}

@media screen and (max-width: 768px) {
  .prevButton {
    padding: 4px 15px;
  }
  .icon {
    color: #fff;
    font-size: 20px;
    margin-left: 0px;
    // margin-top: 60px;
  }
}
