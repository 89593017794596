.deliveryChooseWrapper {
  .circleStyle {
    color: #156bec;

  }

    .titlecontent {
      display: grid;
      grid-template-columns: 10% auto;
    }

  }
  
  @media screen and (max-width: 768px) {
    .packageChooseWrapper {
      .titlecontent {
        display: grid;
        grid-template-columns: 10% 90%;
        background-color: #156bec;
      }
    }
  }