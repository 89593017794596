.getInTouchWrapper {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .inTouchIMGWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    .inTouchIMG {
      display: block;
      width: 25.1rem;
      height: 20.1rem;
    }
  }
  .subtitle {
    display: inline-block;
    margin-bottom: 2.4rem;
  }
}

@media screen and (max-width: 768px) {
  .getInTouchWrapper {
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
  }
}
