.rentalTermWrapper {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .subtitle {
    font-size: 1.6rem;
    display: inline-block;
    margin-top: 1.2rem;
    color: #333;
  }
  .cardWrapepr {
    margin-top: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .rentalTermWrapper {
    .subtitle {
      width: 200px;
    }
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
  }
}
