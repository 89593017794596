.deliveryInformationWrapper {
  padding: 1.6rem;
  background-color: #f7f7f7;
  border-radius: 0.8rem;
  margin-bottom: 3.2rem;
  .deliveryTitle {
    font-size: 1.6rem;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #dadada;
    padding-bottom: 0.1rem;
  }
  .infoSection {
    margin-top: 1.6rem;
    font-size: 1.2rem;
    .infoData {
      margin-bottom: 1rem;
    }
    .info1 {
      margin-left: 3.6rem;
    }
    .info2 {
      margin-left: 5rem;
    }
    .info3 {
      margin-left: 4.7rem;
    }
  }
}
