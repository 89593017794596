.CheckPhoneWrapper {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .inputNumbersWrapper {
    display: flex;
    justify-content: center;
    .inputNumbers {
      input {
        background-color: #fff;
        border: none;
        border-bottom: 4px solid #c4c4c4;
        outline: none;
        text-align: center;
        margin: 4.8rem 1.6rem 4.8rem 0;
        font-weight: 900;
        font-size: 5.6rem;
        width: 8.1rem;
        border-radius: 0;
        // height: 7.4rem;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .CheckPhoneWrapper {
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
    .inputNumbersWrapper {
      // margin: 4.8rem 0rem 4.8rem 0;
      .inputNumbers {
        // input {
        //   margin: 0rem 1.6rem 0rem 0;
        //   font-size: 5.6rem;
        //   width: 6.1rem;
        //   // height: 7.4rem;
        // }
        input {
          background-color: #ffffff;
          border: none;
          border-bottom: 4px solid #c4c4c4;
          font-size: 30px;
          font-weight: 900;
          height: 60px;
          padding: 0;
          outline: none;
          margin-right: 7px;
          text-align: center;
          width: 60px;
          border-radius: 0;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}
