@mixin wrapper($color) {
    user-select: none;
    border: 1px solid $color;
    border-radius: 8px;
    padding: 2.4rem;
    margin-bottom: 1.6rem;
    cursor: pointer;
  }
  .selectCardWrapper {
    @include wrapper(#dadada);
  }
  .selectCardWrapperActives {
    @include wrapper(#156bec);
  }
  .cardFirsRow {
    font-size: 1.8rem;
    color: #333333;
  }
  .circleStyle {
    color: #156bec;
  }
  .subtitle {
    font-size: 1.2rem;
    display: inline-block;
    margin-top: 1.2rem;
  }
  .cardbody {
    display: flex;
    i,
    div {
      margin-top: 1.8rem;
    }
    .text {
      margin-left: 0.9rem;
      color: #333333;
      font-size: 1.2rem;
      font-weight: 400;
    }
    .location {
      text-transform: uppercase;
      margin-top: 0;
      color: #777777;
      line-height: 11.74px;
      width: 100%;
      margin-top: 0.8rem;
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .cardbody {
      display: flex;
      i,
      div {
        margin-top: 1.6rem;
      }
    }
  }
  