.checkoutWrapperCongrats {
  display: grid;
  grid-template-columns: 30% 70% !important;
}
.logo {
  position: absolute;
  top: 40px;
  left: 40px;
}
.checkoutWrapper {
  display: grid;
  grid-template-columns: 40% 60%;
}

.coverIMG {
  width: 100%;
  // max-height: 100vh;
  // object-fit: contain;
  height: 100vh;
  object-fit: cover;
}
.coverIMGForDatePicker {
  width: 100%;
  height: 100vh;
}

.coverIMGLast {
  width: 70%;
  height: 100vh;
}
.left {
  background-color: #baafa0;
  position: relative;

  background-image: url("../../assets/congratsBG.png");
  background-size: cover;
}
.phone {
  height: 80%;
  aspect-ratio: 1/2;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}

.CheckoutProcessForDatePicker {
  padding: 5.8rem 6.4rem 0 5.8rem;
  height: 100% !important;
  overflow-y: scroll !important;
}
.CheckoutProcessCongrats {
  padding: 4.8rem 25% 0 25% !important;
}
.CheckoutProcess {
  padding: 5.8rem 6.4rem;
  height: 100vh;
  overflow-y: auto;
}

.CheckoutProcess::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.158);
  padding: 2px 0;
  background-color: #40404031;
}

.CheckoutProcess::-webkit-scrollbar {
  width: 0.5rem;
}

.CheckoutProcess::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #737272a6;
}

@media screen and (max-width: 768px) {
  .checkoutWrapper {
    display: grid;
    grid-template-columns: 100%;
  }
  .checkoutWrapperCongrats {
    grid-template-columns: 100% !important;
  }
  .left {
    background-color: none;
    background-image: none;
  }
  img {
    display: none;
  }

  .CheckoutProcess,
  .CheckoutProcessForDatePicker,
  .CheckoutProcessCongrats {
    padding: 0 !important;
  }
}

@media screen and (min-width: 820px) and (max-width: 1024px) {
  .CheckoutProcessCongrats {
    padding: 9.8rem 2% 0 37% !important;
  }
}
