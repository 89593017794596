.buttonWrapper {
  width: 100%;
  border-radius: 0.4rem;
  border: none;
  height: 4rem;
}

@media screen and (max-width: 768px) {
  .buttonWrapper {
    width: 100%;
    border-radius: 0.4rem;
    border: none;
    margin-top: 6rem;
  }
}
