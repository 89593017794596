.userInformation {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .formWrapper {
    position: relative;
    .subTitle {
      font-weight: 600;
      font-size: 16px;
    }
    .inputWrapper {
      margin-top: 1.6rem;
      margin-bottom: 3.2rem;
      width: 100%;
      .input {
        border-radius: 5px;
        height: 4.8rem;
      }
    }
  }

  .inputClass {
    height: 48px !important;
    width: 100% !important;
 
  }
  .inputClassError {
    height: 48px !important;
    width: 100% !important;
    border:1px solid red
  }
  .buttonClassError{
    border:1px solid red
  }

  .dropdownClass {
    max-height: 150px !important;
  }
  .agreementWrapper{
    display: flex;
    align-items: flex-start;
    .agreement{
      display: block;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .checkBoxError{
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    padding:5px
  }
}

@media screen and (max-width: 768px) {
  .userInformation {
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
    .formWrapper {
      position: relative;
      top: -20px;
      padding: 2.4rem 2.4rem 0.1rem 2.4rem;
      background-color: #fff;
      border-radius: 20px 20px 0 0;
    }
    .agreementWrapper{

      .agreement{
  
        font-size: 10px;
  
      }
    }
  }
}
