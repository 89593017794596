.App {
  font-family: Maax;
}
.ant-btn-primary[disabled] {
  background-color: #dadada !important;
}
button.ant-picker-header-prev-btn {
  position: absolute;
  right: 20px;
}
button.ant-picker-header-super-prev-btn,
button.ant-picker-header-super-next-btn {
  display: none;
}
.ant-picker-month-btn {
  position: absolute;
  left: 20px;
}
.ant-picker-year-btn {
  position: absolute;
  left: 40px;
}
.ant-picker-panel-container {
  box-shadow: none;
  background-color: #f7f7f7 !important;
  border: 1px solid #e6e6e6;
  border-radius: 10px !important;
}

.ant-picker-cell-inner::before,
.ant-picker-cell-selected::before {
  border: 1px solid transparent !important;
}
.ant-picker-cell-today .ant-picker-cell-inner {
  color: white;
  background-color: #1890ff !important;
}

.ant-picker-cell-inner {
  border-radius: 50% !important;
}
.ant-picker-cell-inner:hover {
  background-color: #cadbf5 !important;
  color: #1890ff;
}
.ant-picker-content thead tr th {
  font-weight: 500;
}

.ant-input-number-input {
  padding: 22px 11px !important;
}

.ant-form-item-explain {
  position: absolute !important;
  width: 100% !important;
  top: 5rem !important;
  left: 1.2rem !important;
}
.ant-form-item-explain-error {
  font-size: 1rem;
  color: #ea234f;

}
.agreement{
  .ant-form-item-explain-error {
    position: relative;
    font-size: 1rem;
    color: #ff4d4f;
    margin-top: -2px;
    margin-left: 0px;

  }
}

@media screen and (max-width: 768px) {
  .agreement{
    .ant-form-item-explain-error {
      margin-top: 10px;
      
    }
  }
}


.ant-checkbox-checked .ant-checkbox-inner {
  background-color: red;
  border-color: red;
}
.ant-select-selector {
  height: 48px !important;
  border-radius: 5px !important;
}
.ant-form-item-with-help {
  margin-top: 0;
}

.custom-class {
  width: 100%;
}
.ant-message-notice-content {
  width: 227px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding: 7px !important;
}
@font-face {
  font-family: Raleway-SemiBold;
  src: url(./assets/fonts/Raleway-SemiBold.ttf);
}

@font-face {
  font-family: Raleway-Regular;
  src: url(./assets/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: Maax;
  src: url(./assets/fonts/Maax-Regular.woff);
}

@font-face {
  font-family: "Maax";
  font-weight: 400;
  src: url("./assets/fonts/Maax-Regular.woff2") format("woff2");
}
