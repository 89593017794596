.PaymentDetailWrapper {
  padding: 1.6rem;
  background-color: #f7f7f7;
  border-radius: 0.8rem;
  .color {
    color: #333 !important;
  }
  .paymentTitle {
    font-size: 1.6rem;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #dadada;
    padding-bottom: 0.1rem;
    color: #333;
  }
  .firstRowWrapper {
    margin-top: 1.6rem;
    line-height: 1.878rem;
    margin-bottom: 0.2rem;
    .cardFirsRow {
      font-weight: 500;
      font-size: 1.8rem;
      color: #333;
    }
  }
  .paymentInformation {
    font-size: 1.2rem;
    font-weight: 400;
    color: #777777;
    line-height: 1.409rem;
    display: inline-block;
    margin-top: 0.8rem;
  }

  .device {
    font-size: 1.4rem;
    padding-top: 1.6rem;
    line-height: 1.644rem;
    color: #333;
    span:nth-child(2) {
      padding-left: 3.5rem;
      color: #333;
    }
  }
  .addOns {
    font-size: 1.4rem;
    padding-top: 1.2rem;
    line-height: 1.644rem;
    color: #333;
    span:nth-child(2) {
      padding-left: 2.4rem;
      color: #333;
    }
  }
  .cargoPrice {
    font-size: 1.4rem;
    line-height: 1.644rem;
    color: #333;
  }

  .discount,
  .security,
  .total {
    padding-top: 1.2rem;
    line-height: 1.644rem;
    color: #333 !important;
  }
  .total {
    font-weight: 600;
    line-height: 1.644rem;
    color: #333;
  }
}
