.ButtonWrapperMain {
  .ButtonWrapper {
    background-color: #f7f7f7;
    padding: 1.6rem;

    p,
    div {
      padding: 0;
      margin: 0;
      text-align: center;
      margin-top: 1.6rem;
      font-size: 1.2rem;
    }
  }
  .infoText {
    padding-top: 1.6rem;
    font-size: 1.2rem;
    color: #333;
  }
}

@media screen and (max-width: 768px) {
  .ButtonWrapperMain {
    .infoText {
      padding: 1.6rem 2.4rem 3.2rem 2.4rem;
    }
    .ButtonWrapper {
      background-color: #f7f7f7;
      padding: 1.6rem;
      margin: 0 2.4rem;
      margin-top: -7.4rem;
    }
  }
}
