.floatLabel {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.labelFloat {
  top: -9px;
  font-size: 10px !important;
  padding: 1px;
  background: white;
}
