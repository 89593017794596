.formWrapper {
  position: relative;
}

@media screen and (max-width: 768px) {
  .formWrapper {
    position: relative;
    top: -20px;
    padding: 2.4rem;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
  }
}
