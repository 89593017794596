@mixin font($mt, $fw) {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.878rem;
  margin-top: $mt;
  font-weight: $fw;
}

.pickupWrapper {
  .titlecontent {
    display: grid;
    grid-template-columns: 10% auto;
  }
  .subtitle {
    @include font(2.4rem, 600);
  }
  .subtitleInfo {
    @include font(1.6rem, 400);
  }
  ul {
    margin-top: 1rem;
    line-height: 3.2rem;
    padding-left: 3rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #dadada;
    width: 100%;
  }

  .sectionTitle {
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
    display: inline-block;
  }
}

.section {
  margin-top: 262px;
}
.DatePickerContainerWrapper {
  position: relative;
  border: 1px solid transparent;
  margin-left: calc(50% - 140px);
}

.mainClass {
  position: relative !important;
}
.dropdownClassName {
  position: relative !important;
  display: inline-block;
}

.firstTextClassName {
  font-size: 1.6rem;
  color: #333333;
}
.secondTextClassName {
  font-size: 1.4rem;
  color: #777777;
}

@media screen and (max-width: 768px) {
  .pickupWrapper {
    .titlecontent {
      display: grid;
      grid-template-columns: 10% 90%;
      background-color: #156bec;
    }
  }
}
